import React, { useState, useEffect, useRef } from "react";
import { Group } from "@visx/group";
import { Pie } from "@visx/shape";
import { Text } from "@visx/text";
import { withTooltip, TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { useTheme } from "@mui/material";

interface PieChartProps {
  data: {
    label: string;
    value: number;
  }[];
  centerText: string | number | undefined;
}

const tooltipStyles = {
  ...defaultStyles,
  background: "#0F1526",
  color: "white",
  borderRadius: 4,
  padding: "4px 8px",
};

function PieChart({ data, centerText }: PieChartProps) {
  const [tooltipData, setTooltipData] = useState<{
    label: string;
    value: number;
  } | null>(null);
  const [tooltipLeft, setTooltipLeft] = useState<number | null>(null);
  const [tooltipTop, setTooltipTop] = useState<number | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const colors = [theme.palette.primary[100], theme.palette.yellow[100]];

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth);
      }
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions();

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const total = data.reduce((acc, d) => acc + d.value, 0);
  const radius = Math.min(containerWidth, containerWidth) / 2;

  const handleMouseOver = (
    event: React.MouseEvent<SVGPathElement>,
    d: { label: string; value: number }
  ) => {
    const coords = localPoint(event);
    setTooltipData(d);
    setTooltipLeft(coords?.x || 0);
    setTooltipTop(coords?.y || 0);
  };

  const handleMouseOut = () => {
    setTooltipData(null);
    setTooltipLeft(null);
    setTooltipTop(null);
  };

  return (
    <div ref={containerRef} style={{ width: "100%", position: "relative" }}>
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${containerWidth} ${containerWidth}`}
        preserveAspectRatio="xMidYMid meet"
      >
        <Group top={containerWidth / 2} left={containerWidth / 2}>
          {total > 0 ? (
            <Pie
              data={data}
              pieValue={(d) => d.value}
              outerRadius={radius * 0.8}
              innerRadius={radius * 0.6}
              padAngle={0.02}
            >
              {(pie) => (
                <g>
                  {pie.arcs.map((arc, i) => (
                    <g
                      key={`arc-${i}`}
                      onMouseEnter={(e: any) => handleMouseOver(e, data[i])}
                      onMouseLeave={handleMouseOut}
                    >
                      <path d={pie.path(arc) || ""} fill={colors[i]} />
                    </g>
                  ))}
                </g>
              )}
            </Pie>
          ) : (
            <circle cx={0} cy={0} r={radius * 0.8} fill="#e0e0e0" />
          )}
          <circle cx={0} cy={0} r={radius * 0.6} fill="white" />
          {centerText !== "" && (
            <Text
              textAnchor="middle"
              verticalAnchor="middle"
              fontFamily="Arial"
              fontSize={`${32 / 16}rem`}
              fontWeight="600"
              fill="#0F1526"
            >
              {centerText}
            </Text>
          )}
        </Group>
      </svg>
      {tooltipData && tooltipLeft !== null && tooltipTop !== null && (
        <TooltipWithBounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div>
            <strong>{tooltipData.label}</strong>: {tooltipData.value}
          </div>
        </TooltipWithBounds>
      )}
    </div>
  );
}

export default withTooltip(PieChart);
