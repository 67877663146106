import { InitStripoOptions } from "./Stripo.types";

export function createStripoScript(): HTMLScriptElement {
  const script = document.createElement("script");
  script.id = "stripoScript";
  script.type = "text/javascript";
  script.src = "https://plugins.stripo.email/static/latest/stripo.js";
  script.async = true;
  return script;
}

export function initStripo(options: InitStripoOptions): void {
  const {
    accountId,
    css,
    emailId,
    html,
    onTemplateLoaded,
    onToggleCodeEditor,
    onTemplateChange,
    onHistoryHide,
    onHistoryLoad,
    getAuthToken,
    userFullName,
    viewOnly,
    onNotificationError,
    codeEditorButtonId,
  } = options;
  const apiRequestData = {
    emailId,
    accountId,
  };
  const initOptions: any = {
    viewOnly,
    apiRequestData: apiRequestData,
    codeEditorButtonId,
    css,
    getAuthToken: function (callback: (token: string) => void) {
      getAuthToken().then((token) => token && callback(token));
    },
    html,
    locale: "en",
    onTemplateLoaded,
    onToggleCodeEditor,
    previewId: "stripoPreviewContainer",
    settingsId: "stripoSettingsContainer",
    userFullName,
    draft: {
      hideAutoSaveLoader: function () {
        onTemplateChange?.();
      },
    },
    versionHistory: {
      changeHistoryLinkId: "stripoHistoryButton",
      onChange: function () {
        // console.log("History change:", args);
      },
    },
    notifications: {
      loader: (_: string, origin: string) => {
        if (origin === "changeHistoryLoader") {
          onHistoryLoad?.();
        }
      },
      hide: (origin: string) => {
        if (origin === "changeHistoryLoader") {
          onHistoryHide?.();
        }
      },
      success: (message: string) => {
        if (message === "Email message has been restored successfully.") {
          onHistoryHide?.();
        }
      },
      error: (message: string) => {
        onNotificationError(message);
      },
    },
    customAppearanceMergetags: true,
    mergeTags: [
      {
        category: "TinyEmail",
        entries: [
          { label: "Email", value: "[CUSTOMER_EMAIL]" },
          { label: "First Name", value: "[CUSTOMER_FIRST_NAME]" },
          { label: "Last Name", value: "[CUSTOMER_LAST_NAME]" },
          { label: "Phone", value: "[CUSTOMER_PHONE]" },
          { label: "Birthday", value: "[CUSTOMER_BIRTHDAY]" },
          { label: "Company", value: "[CUSTOMER_COMPANY]" },
          { label: "Full Address", value: "[CUSTOMER_FULL_ADDRESS]" },
          { label: "Customer City", value: "[CUSTOMER_CITY]" },
          { label: "State/Province", value: "[CUSTOMER_STATE]" },
          { label: "Customer Country", value: "[CUSTOMER_COUNTRY]" },
          { label: "Postal Code", value: "[CUSTOMER_POSTAL_CODE]" },
          { label: "Campaign ID", value: "[CAMPAIGN_ID]" },
          { label: "Customer ID", value: "[CUSTOMER_ID]" },
          { label: "Sender ID", value: "[SENDER_DETAILS_ID]" },
          { label: "Currency", value: "[CUSTOMER_CURRENCY]" },
          { label: "Lifetime Value", value: "[CUSTOMER_LIFETIME_VALUE]" },
          {
            label: "Last Purchase Order",
            value: "[CUSTOMER_LAST_PURCHASE_ORDER]",
          },
          {
            label: "Last Purchase Price",
            value: "[CUSTOMER_LAST_PURCHASE_PRICE]",
          },
          { label: "Number of orders", value: "[CUSTOMER_NUMBER_OF_ORDERS]" },
          { label: "Status", value: "[CUSTOMER_STATUS]" },
          { label: "Created At", value: "[CUSTOMER_CREATED_AT]" },
        ],
      },
    ],
    enableXSSSecurity: true,
  };
  // @ts-ignore
  window.Stripo.init(initOptions);
}
