import React from "react";
import { Box, Card, CardContent, Divider } from "@mui/material";
import { PieChart } from "../../Charts";
import CardHeader from "./CardHeader";
import MetricInfoCard from "../MetricInfoCard/MetricInfoCard";
import { Metric } from "../../MetricCard/types";

interface PieChartCardProps {
  tooltipMessage: string;
  metrics: Metric[];
}

const PieChartCard: React.FC<PieChartCardProps> = ({
  tooltipMessage,
  metrics,
}) => {
  const headerText = metrics[0]?.title || "";
  const sum = metrics[0]?.rate;

  const data = metrics.slice(1).map((metric) => ({
    label: metric.title,
    value: metric.value,
    image: metric.image,
    rate: metric.rate,
  }));

  const pieChartData = data.map((metric) => ({
    label: metric.label,
    value: metric.rate || 0,
  }));

  return (
    <Card
      sx={{
        borderRadius: 4,
        boxShadow: "none",
        p: 6,
        width: "100%",
      }}
    >
      <CardContent sx={{ p: "0 !important" }}>
        <CardHeader text={headerText} tooltipMessage={tooltipMessage} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: "column", md: "row" }}
        >
          <PieChart data={pieChartData} centerText={sum} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: { xs: "100%", lg: "60%" },
              height: "100%",
            }}
            gap={{ xs: 2, md: 4, lg: 8 }}
          >
            {data.map((metric, i) => (
              <React.Fragment key={i}>
                <MetricInfoCard
                  image={metric.image || ""}
                  label={metric.label}
                  value={metric.value}
                />
                {i < data.length - 1 && <Divider sx={{ width: "100%" }} />}
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default React.memo(PieChartCard);
