function formatNumber(value?: number, digits = 0): string {
  if (value === undefined) {
    return "";
  }
  return Number(value).toLocaleString("en-US", {
    minimumFractionDigits: digits,
  });
}

function formatPercent(value?: number, digits = 2): string {
  if (value === undefined || isNaN(value) || !isFinite(value)) {
    return "0.0%";
  }
  return value.toFixed(digits) + "%";
}

function formatAmount(value?: number, currency?: string): string {
  if (value === undefined) {
    return "";
  }

  if (currency === "") {
    return "N/A";
  }

  return Number(value).toLocaleString("en-US", {
    style: "currency",
    currency: currency ?? "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export { formatNumber, formatAmount, formatPercent };
